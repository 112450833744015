
import Vue from "vue";
import { mapGetters } from "vuex";
import { HTTP } from "@/utils/http-common";

export default Vue.extend({
  data(): any {
    return {
      showLoadingScreen: true,
      markMessage: "",
      company: {},
      users: {},
      companies: [],
      packages: [],
      formMessage: "",
      newMessage: false,
      publishMessage: "",
      itemId: "",
      multiselectOptions: [],
      editingModel: {
        user: {
          email: "",
          role: "",
          companies: [Number(this.id)],
        },
        editions: {
          company: [Number(this.id)],
          package: "",
          edition: "",
        },
        informations: {
          name: "",
          website: "",
          phone: "",
          industry: "",
        },
        existingUser: {
          company: [Number(this.id)],
          user: "",
        },
      },
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["editionsNames", "visibleEditionsList"]),
    mapUsers() {
      return this.company.users.map((user: any) => {
        return {
          id: user.id,
          email: user.email,
          role: user.role,
        };
      });
    },
  },
  methods: {
    getCompanyData() {
      HTTP.get(`api/company/${this.id}`)
        .then((response) => {
          this.company = response.data.data;
          this.showLoadingScreen = false;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getAllCompanies() {
      HTTP.get(`api/companies`)
        .then((response) => {
          response.data.data.map((company: any) => {
            this.multiselectOptions.push(company.name);
            this.companies.push({
              id: company.id,
              name: company.name,
            });
          });
        })
        .catch((e) => {
          // console.error(e);
        });
    },
    addMessage(message: string, formMessage: string) {
      this.newMessage = message;
      this.formMessage = formMessage;
      this.itemId = this.company.id;
      if (message === "editions") {
        return;
      }
      if (message === "informations") {
        this.editingModel.informations.name = this.company.name;
        this.editingModel.informations.website = this.company.website;
        this.editingModel.informations.phone = this.company.phone;
        this.editingModel.informations.industry = this.company.industry;
      }
    },
    deleteEdition(editionId: any) {
      HTTP.delete(`api/company/package/${this.id}/${editionId}`);
    },
    startEditing(values: any) {
      this.newMessage = "edit";
      this.itemId = values.id;
      this.editingModel.user.email = values.email;
      this.editingModel.user.companies = values.companies;
      this.editingModel.user.role = values.role;
    },
    getPackagesData() {
      HTTP.get(`api/packages`)
        .then((response) => {
          this.packages = response.data.data;
        })
        .catch((e) => {
          // console.error(e);
        });
    },
    getAllUsers() {
      HTTP.get(`api/users`)
        .then((response) => {
          this.users = response.data.data;
        })
        .catch((e) => {
          // console.error(e);
        });
    },
  },
  mounted() {
    this.getCompanyData();
    this.getAllCompanies();
    this.getPackagesData();
    this.getAllUsers();
  },
});
